/* eslint react/no-unknown-property: 0 */

import { Mesh, MeshStandardMaterial } from 'three'

import React, { useMemo } from 'react'
import { Html, useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { getScreenTexture } from 'utils/threejsUtils'
import { I3DModelProps } from '../types'
import { CircularProgress } from '@mui/material'

type GLTFResult = GLTF & {
  nodes: {
    Plane: Mesh
    Plane_1: Mesh
    Plane_2: Mesh
    Plane_3: Mesh
    Plane_4: Mesh
    Screen: Mesh
  }
  materials: {
    metal: MeshStandardMaterial
    ['matte black']: MeshStandardMaterial
    ['glass type 2']: MeshStandardMaterial
    glass: MeshStandardMaterial
    black: MeshStandardMaterial
  }
}

export default function TabletHorizontalModel({
  setRef = () => null,
  screenMedia,
  loadingContent,
  ...props
}: I3DModelProps) {
  const { nodes, materials } = useGLTF(
    '/assets/3dmodels/tablet/tablet_horizontal.glb',
  ) as GLTFResult
  const screenTexture = useMemo(() => getScreenTexture(screenMedia), [screenMedia])

  return (
    <group ref={setRef} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Plane.geometry} material={materials.metal} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_1.geometry}
        material={materials['matte black']}
      />
      <mesh castShadow receiveShadow geometry={nodes.Plane_2.geometry} material={materials.glass} />
      <mesh castShadow receiveShadow geometry={nodes.Plane_3.geometry} material={materials.black} />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane_4.geometry}
        material={materials['glass type 2']}
      />
      {screenTexture && (
        <mesh geometry={nodes.Screen.geometry} material={materials.black}>
          <meshBasicMaterial color="black" />
          {loadingContent ? (
            <Html transform position={[0, -0.1, 0]}>
              <CircularProgress size="20px" sx={{ color: '#009CD8' }} />
            </Html>
          ) : (
            <meshBasicMaterial map={screenTexture} toneMapped={false} />
          )}
        </mesh>
      )}
    </group>
  )
}

useGLTF.preload('/assets/3dmodels/tablet/tablet_horizontal.glb')
