import axios, { apiBaseURL } from '../axios'

const projectsBaseURL = `${apiBaseURL}/projects`

export default class ProjectsApi {
  static getProjects(params: IProjectParams): Promise<IProjects> {
    return axios.get(`${projectsBaseURL}`, {
      params,
    })
  }

  static getProject(slug: string, params?: IProjectParams): Promise<IProjectDetails> {
    return axios.get(`${projectsBaseURL}/${slug}`, {
      params,
    })
  }
}
