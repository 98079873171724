import { Canvas as ThreejsCanvas } from '@react-three/fiber'

const Canvas = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <ThreejsCanvas
      shadows
      gl={{
        preserveDrawingBuffer: true,
      }}
    >
      {children}
    </ThreejsCanvas>
  )
}

export default Canvas
